import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Navbar from "../Components/NavBar/NavBar";
const BlogDetails = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const baseURL = process.env.REACT_APP_API_URL;
  console.log(process.env);

  useEffect(() => {
    axios
      .get(`https://mayaitsolution.com/api/GetBlogDetailById/${id}`)
      .then((res) => {
        console.log(res.data.data);
        setBlog(res.data.data); // Directly set blog data without checking status
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to load blog details");
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div>
        {/* Header */}
        <Navbar />
        {/* Cart */}
        <div className="wrap-header-cart js-panel-cart">
          <div className="s-full js-hide-cart" />
          <div className="header-cart flex-col-l p-l-65 p-r-25">
            <div className="header-cart-title flex-w flex-sb-m p-b-8">
              <span className="mtext-103 cl2">Your Cart</span>
              <div className="fs-35 lh-10 cl2 p-lr-5 pointer hov-cl1 trans-04 js-hide-cart">
                <i className="zmdi zmdi-close" />
              </div>
            </div>
            <div className="header-cart-content flex-w js-pscroll">
              <ul className="header-cart-wrapitem w-full">
                <li className="header-cart-item flex-w flex-t m-b-12">
                  <div className="header-cart-item-img">
                    <img src="images/item-cart-01.jpg" alt="IMG" />
                  </div>
                  <div className="header-cart-item-txt p-t-8">
                    <a
                      href="#"
                      className="header-cart-item-name m-b-18 hov-cl1 trans-04"
                    >
                      White Shirt Pleat
                    </a>
                    <span className="header-cart-item-info">1 x $19.00</span>
                  </div>
                </li>
                <li className="header-cart-item flex-w flex-t m-b-12">
                  <div className="header-cart-item-img">
                    <img src="images/item-cart-02.jpg" alt="IMG" />
                  </div>
                  <div className="header-cart-item-txt p-t-8">
                    <a
                      href="#"
                      className="header-cart-item-name m-b-18 hov-cl1 trans-04"
                    >
                      Converse All Star
                    </a>
                    <span className="header-cart-item-info">1 x $39.00</span>
                  </div>
                </li>
                <li className="header-cart-item flex-w flex-t m-b-12">
                  <div className="header-cart-item-img">
                    <img src="images/item-cart-03.jpg" alt="IMG" />
                  </div>
                  <div className="header-cart-item-txt p-t-8">
                    <a
                      href="#"
                      className="header-cart-item-name m-b-18 hov-cl1 trans-04"
                    >
                      Nixon Porter Leather
                    </a>
                    <span className="header-cart-item-info">1 x $17.00</span>
                  </div>
                </li>
              </ul>
              <div className="w-full">
                <div className="header-cart-total w-full p-tb-40">
                  Total: $75.00
                </div>
                <div className="header-cart-buttons flex-w w-full">
                  <a
                    href="shoping-cart.html"
                    className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-r-8 m-b-10"
                  >
                    View Cart
                  </a>
                  <a
                    href="shoping-cart.html"
                    className="flex-c-m stext-101 cl0 size-107 bg3 bor2 hov-btn3 p-lr-15 trans-04 m-b-10"
                  >
                    Check Out
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* breadcrumb */}
        <div className="container">
          <div className="bread-crumb flex-w p-l-25 p-r-15 p-t-30 p-lr-0-lg">
            <a href="index.html" className="stext-109 cl8 hov-cl1 trans-04">
              Home
              <i
                className="fa fa-angle-right m-l-9 m-r-10"
                aria-hidden="true"
              />
            </a>
            <a href="blog.html" className="stext-109 cl8 hov-cl1 trans-04">
              Blog
              <i
                className="fa fa-angle-right m-l-9 m-r-10"
                aria-hidden="true"
              />
            </a>
            {/* <span className="stext-109 cl4">
          8 Inspiring Ways to Wear Dresses in the Winter
        </span> */}
          </div>
        </div>
        {/* Content page */}
        <section className="bg0 p-t-52 p-b-20">
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-lg-9 p-b-80">
                <div className="p-r-45 p-r-0-lg">
                  {/*  */}

                  <div className="p-t-32">
                    {blog ? (
                      <div>
                        <h1>
                          {blog.title ? blog.title : "No title available"}
                        </h1>
                        <img
                          src={
                            blog.ogiImage
                              ? `${baseURL}${blog.ogiImage}`
                              : "/assets/images/product-placeholder.jpg"
                          }
                          alt={blog.title || "Blog Image"}
                          className="img-fluid"
                        />
                        <div
                          className="mt-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              blog.description || "<p>No content available</p>",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <p>No blog details available.</p>
                    )}
                  </div>

                  <div className="flex-w flex-t p-t-16">
                    <span className="size-216 stext-116 cl8 p-t-4">Tags</span>
                    <div className="flex-w size-217">
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Streetstyle
                      </a>
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Crafts
                      </a>
                    </div>
                  </div>
                  {/*  */}
                  <div className="p-t-40">
                    <h5 className="mtext-113 cl2 p-b-12">Leave a Comment</h5>
                    <p className="stext-107 cl6 p-b-40">
                      Your email address will not be published. Required fields
                      are marked *
                    </p>
                    <form>
                      <div className="bor19 m-b-20">
                        <textarea
                          className="stext-111 cl2 plh3 size-124 p-lr-18 p-tb-15"
                          name="cmt"
                          placeholder="Comment..."
                          defaultValue={""}
                        />
                      </div>
                      <div className="bor19 size-218 m-b-20">
                        <input
                          className="stext-111 cl2 plh3 size-116 p-lr-18"
                          type="text"
                          name="name"
                          placeholder="Name *"
                        />
                      </div>
                      <div className="bor19 size-218 m-b-20">
                        <input
                          className="stext-111 cl2 plh3 size-116 p-lr-18"
                          type="text"
                          name="email"
                          placeholder="Email *"
                        />
                      </div>
                      <div className="bor19 size-218 m-b-30">
                        <input
                          className="stext-111 cl2 plh3 size-116 p-lr-18"
                          type="text"
                          name="web"
                          placeholder="Website"
                        />
                      </div>
                      <button className="flex-c-m stext-101 cl0 size-125 bg3 bor2 hov-btn3 p-lr-15 trans-04">
                        Post Comment
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-lg-3 p-b-80">
                <div className="side-menu">
                  <div className="bor17 of-hidden pos-relative">
                    <input
                      className="stext-103 cl2 plh4 size-116 p-l-28 p-r-55"
                      type="text"
                      name="search"
                      placeholder="Search"
                    />
                    <button className="flex-c-m size-122 ab-t-r fs-18 cl4 hov-cl1 trans-04">
                      <i className="zmdi zmdi-search" />
                    </button>
                  </div>
                  <div className="p-t-55">
                    <h4 className="mtext-112 cl2 p-b-33">Categories</h4>
                    <ul>
                      <li className="bor18">
                        <a
                          href="#"
                          className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                        >
                          Fashion
                        </a>
                      </li>
                      <li className="bor18">
                        <a
                          href="#"
                          className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                        >
                          Beauty
                        </a>
                      </li>
                      <li className="bor18">
                        <a
                          href="#"
                          className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                        >
                          Street Style
                        </a>
                      </li>
                      <li className="bor18">
                        <a
                          href="#"
                          className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                        >
                          Life Style
                        </a>
                      </li>
                      <li className="bor18">
                        <a
                          href="#"
                          className="dis-block stext-115 cl6 hov-cl1 trans-04 p-tb-8 p-lr-4"
                        >
                          DIY &amp; Crafts
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-t-65">
                    <h4 className="mtext-112 cl2 p-b-33">Featured Products</h4>
                    <ul>
                      <li className="flex-w flex-t p-b-30">
                        <a
                          href="#"
                          className="wrao-pic-w size-214 hov-ovelay1 m-r-20"
                        >
                          <img src="images/product-min-01.jpg" alt="PRODUCT" />
                        </a>
                        <div className="size-215 flex-col-t p-t-8">
                          <a
                            href="#"
                            className="stext-116 cl8 hov-cl1 trans-04"
                          >
                            White Shirt With Pleat Detail Back
                          </a>
                          <span className="stext-116 cl6 p-t-20">$19.00</span>
                        </div>
                      </li>
                      <li className="flex-w flex-t p-b-30">
                        <a
                          href="#"
                          className="wrao-pic-w size-214 hov-ovelay1 m-r-20"
                        >
                          <img src="images/product-min-02.jpg" alt="PRODUCT" />
                        </a>
                        <div className="size-215 flex-col-t p-t-8">
                          <a
                            href="#"
                            className="stext-116 cl8 hov-cl1 trans-04"
                          >
                            Converse All Star Hi Black Canvas
                          </a>
                          <span className="stext-116 cl6 p-t-20">$39.00</span>
                        </div>
                      </li>
                      <li className="flex-w flex-t p-b-30">
                        <a
                          href="#"
                          className="wrao-pic-w size-214 hov-ovelay1 m-r-20"
                        >
                          <img src="images/product-min-03.jpg" alt="PRODUCT" />
                        </a>
                        <div className="size-215 flex-col-t p-t-8">
                          <a
                            href="#"
                            className="stext-116 cl8 hov-cl1 trans-04"
                          >
                            Nixon Porter Leather Watch In Tan
                          </a>
                          <span className="stext-116 cl6 p-t-20">$17.00</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="p-t-55">
                    <h4 className="mtext-112 cl2 p-b-20">Archive</h4>
                    <ul>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>July 2018</span>
                          <span>(9)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>June 2018</span>
                          <span>(39)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>May 2018</span>
                          <span>(29)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>April 2018</span>
                          <span>(35)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>March 2018</span>
                          <span>(22)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>February 2018</span>
                          <span>(32)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>January 2018</span>
                          <span>(21)</span>
                        </a>
                      </li>
                      <li className="p-b-7">
                        <a
                          href="#"
                          className="flex-w flex-sb-m stext-115 cl6 hov-cl1 trans-04 p-tb-2"
                        >
                          <span>December 2017</span>
                          <span>(26)</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="p-t-50">
                    <h4 className="mtext-112 cl2 p-b-27">Tags</h4>
                    <div className="flex-w m-r--5">
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Fashion
                      </a>
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Lifestyle
                      </a>
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Denim
                      </a>
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Streetstyle
                      </a>
                      <a
                        href="#"
                        className="flex-c-m stext-107 cl6 size-301 bor7 p-lr-15 hov-tag1 trans-04 m-r-5 m-b-5"
                      >
                        Crafts
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Footer */}
        <footer className="bg3 p-t-75 p-b-32">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Categories</h4>
                <ul>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Women
                    </a>
                  </li>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Men
                    </a>
                  </li>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Shoes
                    </a>
                  </li>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Watches
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Help</h4>
                <ul>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Track Order
                    </a>
                  </li>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Returns
                    </a>
                  </li>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      Shipping
                    </a>
                  </li>
                  <li className="p-b-10">
                    <a href="#" className="stext-107 cl7 hov-cl1 trans-04">
                      FAQs
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">GET IN TOUCH</h4>
                <p className="stext-107 cl7 size-201">
                  Any questions? Let us know in store at 8th floor, 379 Hudson
                  St, New York, NY 10018 or call us on (+1) 96 716 6879
                </p>
                <div className="p-t-27">
                  <a href="#" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-facebook" />
                  </a>
                  <a href="#" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-instagram" />
                  </a>
                  <a href="#" className="fs-18 cl7 hov-cl1 trans-04 m-r-16">
                    <i className="fa fa-pinterest-p" />
                  </a>
                </div>
              </div>
              <div className="col-sm-6 col-lg-3 p-b-50">
                <h4 className="stext-301 cl0 p-b-30">Newsletter</h4>
                <form>
                  <div className="wrap-input1 w-full p-b-4">
                    <input
                      className="input1 bg-none plh1 stext-107 cl7"
                      type="text"
                      name="email"
                      placeholder="email@example.com"
                    />
                    <div className="focus-input1 trans-04" />
                  </div>
                  <div className="p-t-18">
                    <button className="flex-c-m stext-101 cl0 size-103 bg1 bor1 hov-btn2 p-lr-15 trans-04">
                      Subscribe
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="p-t-40">
              <div className="flex-c-m flex-w p-b-18">
                <a href="#" className="m-all-1">
                  <img src="images/icons/icon-pay-01.png" alt="ICON-PAY" />
                </a>
                <a href="#" className="m-all-1">
                  <img src="images/icons/icon-pay-02.png" alt="ICON-PAY" />
                </a>
                <a href="#" className="m-all-1">
                  <img src="images/icons/icon-pay-03.png" alt="ICON-PAY" />
                </a>
                <a href="#" className="m-all-1">
                  <img src="images/icons/icon-pay-04.png" alt="ICON-PAY" />
                </a>
                <a href="#" className="m-all-1">
                  <img src="images/icons/icon-pay-05.png" alt="ICON-PAY" />
                </a>
              </div>
              <p className="stext-107 cl6 txt-center">
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
                Copyright © All rights reserved | Made with{" "}
                <i className="fa fa-heart-o" aria-hidden="true" /> by{" "}
                <a href="https://colorlib.com" target="_blank">
                  Colorlib
                </a>{" "}
                &amp; distributed by{" "}
                <a href="https://themewagon.com" target="_blank">
                  ThemeWagon
                </a>
                {/* Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. */}
              </p>
            </div>
          </div>
        </footer>
        {/* Back to top */}
        <div className="btn-back-to-top" id="myBtn">
          <span className="symbol-btn-back-to-top">
            <i className="zmdi zmdi-chevron-up" />
          </span>
        </div>
        {/*===============================================================================================*/}
        {/*===============================================================================================*/}
        {/*===============================================================================================*/}
        {/*===============================================================================================*/}
        {/*===============================================================================================*/}
        {/*===============================================================================================*/}
        {/*===============================================================================================*/}
      </div>
    </>
  );
};

export default BlogDetails;
