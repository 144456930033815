import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Product = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const baseURL = "https://mayaitsolution.com/Productimages/";

  useEffect(() => {
    axios
      .get("https://mayaitsolution.com/api/GetProductDetailsList/")
      .then((res) => {
        const fetchedData = res.data; // Adjust based on your API response
        setData(
          Array.isArray(fetchedData) ? fetchedData : fetchedData.data || []
        );
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
        <h4 className="text-center text-dark">Loading...</h4>
      </div>
    );
  }

  return (
    <div className="pt-5 bg-light">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <h1>List of Products</h1>
        <div className="w-75">
          <div className="row isotope-grid">
            {Array.isArray(data) &&
              data.map((product) => (
                <div
                  className="col-sm-6 col-md-4 col-lg-3 p-b-35 isotope-item"
                  key={product.id}
                >
                  <div className="block2">
                    <div className="block2-pic hov-img0">
                      <img
                        src={
                          product.bannerImageUrl
                            ? `${baseURL}${product.bannerImageUrl}`
                            : "/assets/images/product-placeholder.jpg"
                        }
                        alt={product.title || "Product Image"}
                      />

                      <a
                        href="#"
                        className="block2-btn flex-c-m stext-103 cl2 size-102 bg0 bor2 hov-btn1 p-lr-15 trans-04 js-show-modal1"
                      >
                        Quick View
                      </a>
                    </div>
                    <div className="block2-txt flex-w flex-t p-t-14">
                      <div className="block2-txt-child1 flex-col-l">
                        <Link
                          to={`/product-detail/${product.id}`}
                          className="stext-104 cl4 hov-cl1 trans-04 js-name-b2 p-b-6"
                        >
                          {product.title || "Product Title"}
                        </Link>
                        <span className="stext-105 cl3">
                          ${product.price || "N/A"}
                        </span>
                      </div>
                      <div className="block2-txt-child2 flex-r p-t-3">
                        <a
                          href="#"
                          className="btn-addwish-b2 dis-block pos-relative js-addwish-b2"
                        >
                          <img
                            className="icon-heart1 dis-block trans-04"
                            src="/assets/images/icons/icon-heart-01.png"
                            alt="Heart Icon"
                          />
                          <img
                            className="icon-heart2 dis-block trans-04 ab-t-l"
                            src="/assets/images/icons/icon-heart-02.png"
                            alt="Heart Icon"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
