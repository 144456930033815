import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Blogs = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  const baseURL = process.env.REACT_APP_API_URL;
  useEffect(() => {
    setloading(true);
    axios
      .get("https://mayaitsolution.com/api/GetBlogDetailList/")
      .then((res) => {
        const fetchedData = res.data; // Adjust this based on your API response
        setData(
          Array.isArray(fetchedData) ? fetchedData : fetchedData.data || []
        );
      })

      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        // Ensure loading is set to false regardless of success or failure
        setloading(false);
      });
  }, []);
  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 bg-light">
        <h4 className="text-center text-dark">
          <span>Loading...</span>
        </h4>
      </div>
    );
  }
  const handleDelete = (id) => {
    const confirm = window.confirm("Would you like to delete this?");
    if (confirm) {
      axios
        .get(`https://mayaitsolution.com/api/DeleteBlogDetail/${id}`) // Correct URL with actual ID
        .then((res) => {
          setData(data.filter((item) => item.id !== id));
        })
        .catch((err) => console.error(err));
    }
  };

  // Helper function to truncate HTML content to the first `wordLimit` words
  const truncateHtml = (htmlString, wordLimit) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    let textContent = "";
    const traverseNode = (node) => {
      if (wordLimit <= 0) return;

      if (node.nodeType === Node.TEXT_NODE) {
        const text = node.textContent || "";
        const words = text.split(/\s+/);
        for (const word of words) {
          if (wordLimit <= 0) break;
          textContent += word + " ";
          wordLimit--;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        textContent += `<${node.nodeName.toLowerCase()}`;
        for (const attr of node.attributes) {
          textContent += ` ${attr.name}="${attr.value}"`;
        }
        textContent += ">";

        for (const child of node.childNodes) {
          if (wordLimit <= 0) break;
          traverseNode(child);
        }

        textContent += `</${node.nodeName.toLowerCase()}>`;
      }
    };

    traverseNode(tempDiv);

    return textContent.trim();
  };

  return (
    <div className="p-b-63">
      {Array.isArray(data) &&
        data.map((d) => (
          <div key={d.id}>
            <div>
              <Link
                to={`/blog-details/${d.id}`}
                className="hov-img0 how-pos5-parent"
              >
                <img
                  src={
                    d.ogiImage
                      ? `${baseURL}${d.ogiImage}`
                      : "/assets/images/product-placeholder.jpg"
                  }
                  alt={d.title || "Product Image"}
                  className="hov-img0 how-pos5-parent"
                />
              </Link>
              <div className="p-t-32">
                <h4 className="p-b-15">
                  <Link
                    to={`/blog-details/${d.id}`}
                    className="ltext-108 cl2 hov-cl1 trans-04"
                  >
                    {d.title || "N/A"}
                  </Link>
                </h4>
                <p
                  className="stext-117 cl6"
                  dangerouslySetInnerHTML={{
                    __html: truncateHtml(d.description || "N/A", 50),
                  }}
                ></p>

                <div className="flex-w flex-sb-m p-t-18">
                  <span className="flex-w flex-m stext-111 cl2 p-r-30 m-tb-10">
                    <span>
                      <span className="cl4">By</span> {d.author || "Admin"}
                      <span className="cl12 m-l-4 m-r-6">|</span>
                    </span>

                    <span>{d.commentsCount || "0"} Comments</span>
                  </span>
                  <Link
                    to={`/blog-details/${d.id}`}
                    className="hov-img0 how-pos5-parent"
                  >
                    Continue Reading
                    <i className="fa fa-long-arrow-right m-l-9" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Blogs;
